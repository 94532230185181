<template>
  <div class="col-12">
    <div class="card">
        <div class="card-header">
            <h4>
                <i class="fa fa-"></i>
                تقييم المشرفين
                <button class="btn btn-sm btn-primary" v-if="add" v-b-modal.create>
                    <i class="fa fa-plus"></i>
                     اضافة تقييم
                </button>
            </h4>
        </div>
        <div class="card-body">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered table-sm">
                    <thead>
                        <th>
                            المشرف
                        </th>
                        <th>
                            الالتزام بشروط العمل وعدم الاخلال به
                        </th>
                        <th>
                            الابداع والابتكار في العمل
                        </th>
                        <th>
                            مستوى القسم في الجودة
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="a in rates" :key="a._id">
                            <td>
                                {{ a.name }}
                            </td>
                            <td>
                                {{ a.rates[1] }}
                            </td>
                            <td>
                                {{ a.rates[2] }}
                            </td>
                            <td>
                                {{ a.rates[3] }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <b-modal id="create" title="اضافة تقييم" hide-footer>
        <div class="form-group">
          <h5 for="">اختر المشرف</h5>
          <select class="form-control" v-model="create.admin_id">
            <option :value="undefined">-- اختر --</option>
            <option v-for="admin in admins" :key="admin._id" :value="admin._id">
                {{ admin.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <h5 for="">الالتزام بشروط العمل وعدم الاخلال به</h5>
          <select class="form-control" v-model="create.rates[1]">
            <option :value="undefined">-- اختر --</option>
            <option v-for="x in ['ممتاز','جيد جداً','جيد','غير جيد']" :key="x" :value="x">{{ x }}</option>
          </select>
        </div>
        <div class="form-group">
          <h5 for="">الابداع والابتكار في العمل</h5>
          <select class="form-control" v-model="create.rates[2]">
            <option :value="undefined">-- اختر --</option>
            <option v-for="x in ['5 درجات','10 درجات','15 درجة']" :key="x" :value="x">{{ x }}</option>
          </select>
        </div>
        <div class="form-group">
          <h5 for="">مستوى القسم في الجودة</h5>
          <select class="form-control" v-model="create.rates[3]">
            <option :value="undefined">-- اختر --</option>
            <option v-for="x in ['ممتاز','جيد جداً','جيد','غير جيد']" :key="x" :value="x">{{ x }}</option>
          </select>
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="rateNow()">
                <i class="fa fa-check"></i>
                تقييم الآن
            </button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BDropdown,
        BDropdownItem,
        BModal
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            user: JSON.parse(localStorage.getItem("user")),
            rates: [],
            create: {rates: {}},
            admins: [],
            add: JSON.parse(localStorage.getItem("user")).pers.includes("rate-all")
        }
    },
    methods: {
        getList(){
            var g = this;
            $.post(api + '/admin/admins/rates-all', {
                jwt: g.user.jwt
            }).then(function(r){
                g.rates = r.response.map(function(x){
                    x.rates = JSON.parse(x.rates)
                    return x;
                });
            })
        },
        rateNow(){
            var g = this;
            $.post(api + '/admin/admins/rate-all', {
                jwt: g.user.jwt,
                admin_id: g.create.admin_id,
                rates: JSON.stringify(g.create.rates)
            }).then(function(r){
                g.getList()
                $('#create___BV_modal_header_ > button').click()
            })
        }
    },
    created(){
        if(!checkPer('rate-all')){
            this.$router.push('/pers')
            return;
        }
        var g = this;
        $.post(api + '/admin/admins/names', {
            jwt: g.user.jwt
        }).then(function(r){
            g.admins = r.response;
        })
        g.getList()
    }
}
</script>

<style>

</style>